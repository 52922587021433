import React from 'react';
import LandingPageTemplate from '../../components/LandingPageTemplate';

import mainImage from '../../images/tuerautomatik_1024.webp';
import mainImage800 from '../../images/tuerautomatik_800.webp';


const context = {
  title: 'Türautomatik', // Große Haupt-Überschrift
  subTitle: 'Automatiktür24 - Türautomatik', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Willkommen in der Welt der Türautomatik - Komfort, Sicherheit und Barrierefreiheit für Ihr Unternehmen',
  image: mainImage, //Haupt-Bild der Landingpage
  image800: mainImage800, //Haupt-Bild der Landingpage
  imageAlt: "Automatiktür-Hersteller von A-Z", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Automatiktür-Beratung anfordern",
  configurator: true, // optional, aktiviert den Konfirgurator Button wenn 'true'
  configuratorButtonText: "Schiebetür online konfigurieren", // optional, überschreibt den default Titel
  suggestedProductsTitle: "Passende Automatiktür24 Produkte", // optional, überschreibt den default Titel
  suggestedProducts: [
    '/produkte/automatische-drehtuer/',
    '/produkte/automatische-schiebetuer/',
    '/produkte/automatische-faltfluegeltuer/'
  ],
  text:
  <div>

    Entdecken Sie die modernen Lösungen der Türautomatik, die Ihr Leben einfacher, sicherer und bequemer machen.
    Unsere innovativen Systeme bieten Ihnen die neueste Technik für automatische Türsysteme in verschiedenen
    Ausführungen für private und gewerbliche Räume.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Komfort auf Knopfdruck - Türautomatik, die Ihr Leben erleichtert</h3>
    Stellen Sie sich vor, die Türen Ihres Hauses oder Ihrer Firma öffnen sich wie von Geisterhand, leise und zuverlässig.
    Mit unserer Türautomatik genießen Sie diesen Luxus – perfekt für alle, die Wert auf Komfort und moderne Wohnstandards legen.
    Egal ob Sie mit Einkaufstaschen beladen nach Hause kommen oder einfach nur das nahtlose Öffnen von Türen genießen möchten:
    Unsere Türautomatik macht es möglich.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Sicherheit geht vor - Robuste Systeme für den Schutz Ihrer Räumlichkeiten</h3>
    Sicherheit ist für uns oberstes Gebot. Die Türautomatiksysteme bieten nicht nur Komfort, sondern garantieren auch,
    dass Türen im Notfall schnell geöffnet oder geschlossen werden können.
    Zudem sind unsere Systeme so konzipiert, dass sie eine unbefugte Nutzung verhindern und so Ihre Sicherheit erhöhen.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Barrierefreie Zugänge - Ein Gewinn für alle</h3>
    Barrierefreiheit ist nicht nur eine Frage der Zugänglichkeit, sondern auch eine der Inklusion.
    Unsere automatischen Türsysteme ermöglichen einen erleichterten Zugang für Menschen mit eingeschränkter Mobilität
    und unterstützen Sie dabei, die Anforderungen der Barrierefreiheit zu erfüllen.
    So öffnen Sie Ihre Türen für jedermann – inklusiv und willkommen heißend.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Energieeffizienz und Umweltbewusstsein</h3>
    Technologischer Fortschritt bedeutet für uns auch, Verantwortung für unsere Umwelt zu übernehmen.
    Daher sind unsere Türautomatiksysteme energieeffizient und tragen dazu bei, Heizkosten zu sparen,
    indem sie dazu beitragen, den Wärmeverlust zu minimieren.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Unsere Produktpalette - Vielfalt, die begeistert</h3>
    Von der einfachen Schiebetür für den Privatgebrauch bis hin zu komplexen Systemen für den Einsatz in öffentlichen
    Einrichtungen oder Bürokomplexen - unsere Produktpalette deckt jedes Bedürfnis ab.
    Lassen Sie sich von unseren Experten beraten, um die perfekte Lösung für Ihre Anforderungen zu finden.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Service, der überzeugt</h3>
    Wir stehen Ihnen nicht nur beim Kauf zur Seite, sondern bieten auch einen umfassenden After-Sales-Service.
    Von der Installation über Wartung bis hin zur Reparatur – unser erfahrenes Service-Team sorgt dafür,
    dass Ihre Türautomatik stets reibungslos funktioniert.

    <h3 className="mt-8 mb-3 text-lg font-semibold">Ihr Schritt in die Zukunft der Türtechnik</h3>
    Wir laden Sie ein, Teil dieser innovativen Welt der automatischen Türsysteme zu werden. Kontaktieren Sie uns jetzt
    für eine persönliche Beratung oder laden Sie unseren kostenlosen Katalog herunter. Erfahren Sie mehr darüber,
    wie unsere Türautomatik Ihren Alltag erleichtern kann.

    Sichern Sie sich jetzt den Zugang zu mehr Komfort, Sicherheit und Barrierefreiheit.
    Wir freuen uns auf Ihre Anfrage!
  </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
